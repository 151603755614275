<template>
  <v-container fluid class="px-11">
    <v-autocomplete
      v-model="applications.selected"
      :items="applications.data"
      label="Choose application"
      cache-items
      item-text="display_name"
      auto-select-first
      return-object
      @change="getRolesForApp"
    ></v-autocomplete>

    <v-text-field v-model="search" name="Search" label="Search" prepend-inner-icon="search"></v-text-field>
    <v-skeleton-loader type="table" :loading="pageLoaded" :transition="'slide-y-transition'">
      <v-data-table :search="search" class="background mr-6" :headers="headers" :items="roles">
        <template v-slot:item.actions="{ item }">
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <v-btn
                elevation="0"
                @click="getPermissionsForRole(item)"
                slot="activator"
                fab
                small
                dark
                v-on="on"
                color="primary"
                :class="$vuetify.breakpoint.width > 960 ? 'ml-0 mr-3 xs-small' : 'ml-3 xs-small'"
              >
                <v-icon small>edit</v-icon>
              </v-btn>
            </template>
            <span>Edit Permissions</span>
          </v-tooltip>
          <v-tooltip top dark>
            <template v-slot:activator="{ on }">
              <v-btn
                elevation="0"
                @click="deleteRole(item)"
                slot="activator"
                fab
                small
                dark
                v-on="on"
                color="primary"
                :class="$vuetify.breakpoint.width > 960 ? 'ml-0 mr-3 xs-small' : 'ml-3 xs-small'"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </template>
            <span>Delete Role</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-skeleton-loader>
    <v-dialog
      v-model="createNew.dialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title>Create a role</v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="applications.selected"
            :items="applications.data"
            label="Choose application"
            cache-items
            item-text="display_name"
            auto-select-first
            return-object
            @change="getRolesForApp"
          ></v-autocomplete>
          <v-text-field v-model="createNew.name" flat name="Role Name" label="Role name"></v-text-field>
          <v-text-field
            v-model="createNew.description"
            flat
            name="Description"
            label="Add a description"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="createNew.dialog = false" color="grey" text class="text-capitalize">Cancel</v-btn>
          <v-btn
            @click="createNewRole"
            elevation="0"
            color="primary"
            class="text-capitalize"
          >Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Create new floating button -->
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-btn
          @click="createNew.dialog = true"
          fab
          fixed
          dark
          bottom
          right
          color="primary"
          v-on="on"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </template>
      <span>Create a role</span>
    </v-tooltip>
    <!-- Delete Roles Modal -->
    <DeleteModal
      v-if="deleteDialog.status"
      :title="deleteDialog.title"
      :status="deleteDialog.status"
      :callback="deleteDialog.callback"
      @success="getRolesForApp(applications.selected);deleteDialog.status = false"
      @close="deleteDialog.status = false"
    />
    <EditRoleModal
      @fetch-new-resources-and-permissions="getPermissionsForRole(editRoleDialog.selected)"
      v-if="editRoleDialog.status"
      @close-modal="editRoleDialog.status = false"
      :status="editRoleDialog.status"
      :resources="editRoleDialog.resources"
      :role="editRoleDialog.selected"
    />
  </v-container>
</template>

<script>
import { ApplicationsAPI } from '@/clients/permissions/apps.js'
import { RolesAPI } from '@/clients/permissions/roles'

export default {
  async created() {
    await this.getAllApplications()
    if (this.applications.data.length > 0) {
      this.applications.selected = this.applications.data[0]
    }
  },
  data() {
    return {
      search: '',
      applications: {
        data: [],
        selected: null,
        loading: false
      },
      roles: [],
      headers: [
        {
          text: 'Role Name',
          align: 'start',
          sortable: true,
          value: 'display_name'
        },
        {
          text: 'Role Description',
          align: 'start',
          sortable: true,
          value: 'description'
        },
        {
          text: 'Actions',
          align: 'end',
          sortable: false,
          value: 'actions'
        }
      ],
      createNew: {
        dialog: false,
        name: '',
        description: ''
      },
      deleteDialog: {
        status: false,
        title: '',
        description: null,
        callback: null
      },
      editRoleDialog: {
        status: false,
        resources: [],
        selected: null
      },
      pageLoaded: false
    }
  },
  components: {
    DeleteModal: () => import('@/components/custom-components/DeleteModal'),
    EditRoleModal: () => import('./EditRoleModal')
  },
  methods: {
    async getRolesForApp(app) {
      this.pageLoaded = true
      let response
      try {
        response = await ApplicationsAPI.getAllRolesForAnApplication(app.id)
      } catch (error) {
        this.roles = []
        this.pageLoaded = false
        console.log(error)
        return
      }
      this.roles = response.data.payload
      this.pageLoaded = false
    },
    deleteRole(role) {
      this.deleteDialog.status = true
      this.deleteDialog.title = `Are you sure you want to delete ${role.display_name} ?`
      this.deleteDialog.callback = () => RolesAPI.delete(role.id)
    },
    async createNewRole() {
      this.spinner(true)
      let response
      let data = this.createNew
      data['app_id'] = this.applications.selected.id
      try {
        response = await RolesAPI.create(data)
      } catch (error) {
        return
      }
      this.roles.push(response.data.payload)
      this.createNew.dialog = false
      this.createNew.name = ''
      this.createNew.description = ''
      this.spinner(false)
      this.getPermissionsForRole(response.data.payload)
    },
    async getAllApplications() {
      if (this.applications.data.length > 0) {
        return
      }
      let response
      try {
        response = await ApplicationsAPI.getAllApps()
      } catch (error) {
        console.log(error)
      }
      this.applications.data = response.data.payload
    },
    async getPermissionsForRole(role) {
      this.spinner(true)
      let response
      try {
        response = await RolesAPI.getPermissionsForARole(role.id)
      } catch (error) {
        this.spinner(false)
        console.log(error)
        return
      }
      this.spinner(false)
      this.editRoleDialog.selected = role
      this.editRoleDialog.resources = response.data.payload
      this.editRoleDialog.status = true
    }
  },
  computed: {
    showCreateRoleButton() {
      return this.applications.selected !== null
    },
    selectedApplication() {
      return this.applications.selected
    }
  },
  watch: {
    selectedApplication: {
      handler: function (x) {
        this.getRolesForApp(x)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-footer {
  padding-right: 25px !important;
}
</style>
