var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-11",attrs:{"fluid":""}},[_c('v-autocomplete',{attrs:{"items":_vm.applications.data,"label":"Choose application","cache-items":"","item-text":"display_name","auto-select-first":"","return-object":""},on:{"change":_vm.getRolesForApp},model:{value:(_vm.applications.selected),callback:function ($$v) {_vm.$set(_vm.applications, "selected", $$v)},expression:"applications.selected"}}),_c('v-text-field',{attrs:{"name":"Search","label":"Search","prepend-inner-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-skeleton-loader',{attrs:{"type":"table","loading":_vm.pageLoaded,"transition":'slide-y-transition'}},[_c('v-data-table',{staticClass:"background mr-6",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.roles},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.$vuetify.breakpoint.width > 960 ? 'ml-0 mr-3 xs-small' : 'ml-3 xs-small',attrs:{"slot":"activator","elevation":"0","fab":"","small":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.getPermissionsForRole(item)}},slot:"activator"},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Permissions")])]),_c('v-tooltip',{attrs:{"top":"","dark":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:_vm.$vuetify.breakpoint.width > 960 ? 'ml-0 mr-3 xs-small' : 'ml-3 xs-small',attrs:{"slot":"activator","elevation":"0","fab":"","small":"","dark":"","color":"primary"},on:{"click":function($event){return _vm.deleteRole(item)}},slot:"activator"},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Role")])])]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","transition":"dialog-transition"},model:{value:(_vm.createNew.dialog),callback:function ($$v) {_vm.$set(_vm.createNew, "dialog", $$v)},expression:"createNew.dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Create a role")]),_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.applications.data,"label":"Choose application","cache-items":"","item-text":"display_name","auto-select-first":"","return-object":""},on:{"change":_vm.getRolesForApp},model:{value:(_vm.applications.selected),callback:function ($$v) {_vm.$set(_vm.applications, "selected", $$v)},expression:"applications.selected"}}),_c('v-text-field',{attrs:{"flat":"","name":"Role Name","label":"Role name"},model:{value:(_vm.createNew.name),callback:function ($$v) {_vm.$set(_vm.createNew, "name", $$v)},expression:"createNew.name"}}),_c('v-text-field',{attrs:{"flat":"","name":"Description","label":"Add a description"},model:{value:(_vm.createNew.description),callback:function ($$v) {_vm.$set(_vm.createNew, "description", $$v)},expression:"createNew.description"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.createNew.dialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.createNewRole}},[_vm._v("Confirm")])],1)],1)],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","fixed":"","dark":"","bottom":"","right":"","color":"primary"},on:{"click":function($event){_vm.createNew.dialog = true}}},on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("Create a role")])]),(_vm.deleteDialog.status)?_c('DeleteModal',{attrs:{"title":_vm.deleteDialog.title,"status":_vm.deleteDialog.status,"callback":_vm.deleteDialog.callback},on:{"success":function($event){_vm.getRolesForApp(_vm.applications.selected);_vm.deleteDialog.status = false},"close":function($event){_vm.deleteDialog.status = false}}}):_vm._e(),(_vm.editRoleDialog.status)?_c('EditRoleModal',{attrs:{"status":_vm.editRoleDialog.status,"resources":_vm.editRoleDialog.resources,"role":_vm.editRoleDialog.selected},on:{"fetch-new-resources-and-permissions":function($event){return _vm.getPermissionsForRole(_vm.editRoleDialog.selected)},"close-modal":function($event){_vm.editRoleDialog.status = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }